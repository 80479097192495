import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const erfolgreich = () => {
  return (
    <Layout>
      <SEO title="Kursanmeldung erfolgreich" />
      <div className="container">
        <h1 className="text-center mb-5 text-uppercase">
          <strong>Vielen Dank für Ihre Anmeldung.</strong>
        </h1>
      </div>
    </Layout>
  )
}

export default erfolgreich
